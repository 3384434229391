import React from "react";

function HomeSessionFive() {
  return (
    <>
      <section className="" id="about">
        <div className="container py-md-5 py-4">
          <div className="row align-items-center">
            <div className="col-lg-6 homeaboutblock order-lg-first order-last">
              <div className="position-relative img-border">
                <img
                  src={"/image/v1.jpg"}
                  className="img-fluid video-popup-image radius-image"
                  alt="video-popup"
                />
                {/* <picture>
                  <source serSet={"/image/v1.jpeg"} media="(min-width: 992px)" />
                  <source serSet={"/image/v1.jpeg"} media="(min-width: 768px)" />
                  <source serSet={"/image/v1.jpeg"} media="(min-width: 0px)" />
                  <img src={"/image/v1.jpeg"} width="1599" height="899" className="img-fluid video-popup-image radius-image" alt="Logo Img" />
                </picture> */}
              </div>
            </div>
            <div className="col-lg-6 ps-lg-5 mb-lg-0 mb-5 order-lg-last order-first">
              <div className="position-relative">
                <h2 className="title-style">Integrated Machines</h2>
              </div>
              <p className="">
                With our integrated hardness testing machine, you can now
                measure Macro, Micro and Nano hardness with ease! This single
                machine also comes with high temperature (450°C), tensile and
                any other customer-specific tests that you may require.
              </p>
              <p>
                This is a perfect solution for anyone who needs an all-in-one
                solution for testing their materials.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HomeSessionFive;
