import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faBoxOpen, faUser } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";

function DashBtn({ title, icon, styleColor, navLinkTo }) {
  const renderIcon = () => {
    if (icon === "faPlus") {
      return <FontAwesomeIcon className="mdi mdi-account-group" icon={faPlus} />;
    } else if (icon === "faBoxOpen") {
      return <FontAwesomeIcon className="mdi mdi-account-group" icon={faBoxOpen} />;
    } else if (icon === "faUser") {
      return <FontAwesomeIcon className="mdi mdi-account-group" icon={faUser} />;
    } else {
      return <FontAwesomeIcon icon={faPlus} />;
    }
  };
  return (
    <React.Fragment>
      <div className="col-3">
        <NavLink className="" to={navLinkTo} >
          <div className="dash-btn">
            {renderIcon()} {title}
          </div>
        </NavLink>
      </div>
    </React.Fragment>
  );
}

export default DashBtn;
