import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";

function AboutSessionThree() {
  return (
    <>
      <section className="" id="team">
        <div className="container pb-4 ">
          <div className="row ">
            <div className="col-md-7 col-lg-7 col-sm-12 with-bg">
              <div className=""></div>
            </div>
            <div className="col-md-5 col-lg-5 col-sm-12 align-self-center">
              <div className="text-container">
                <p className="">
                  We are Nanatom, a company specializing in material
                  characterization and miniature testing machines, including
                  Instrumented Indentation Systems, Tribology Products, and DAQ
                  systems.
                </p>
                <p className=" ">
                  Our products are designed to enable researchers in various
                  fields, such as medical devices, aerospace components,
                  automotive parts, and consumer electronics, to measure the
                  physical properties of materials accurately and consistently.
                </p>

                <p className=" ">
                  Incubated at Indian Institute of Science, Bangalore as Nanatom &
                  with 50 years of combined man day’s expertise as a company, we
                  undertake Nano & Micro Instrumented Indentation Systems for
                  mechanical testing along with In-situ test stages for dynamic
                  testing.
                </p>
                <p className=" ">
                  Proudly, this is indigenously first time that such a technology
                  is available locally as an import alternative without the need
                  for an End User Certificate.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutSessionThree;
