import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import api from "../../api/services";

function ResetProfile({ profile, onUpdate }) {
  const initialResetValues = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: ""
  };

  const validationResetSchema = Yup.object({
    currentPassword: Yup.string().required("Current Password is required"),
    newPassword: Yup.string()
      .required('No password provided.')
      .min(8, 'Password is too short - should be 8 chars minimum.')
      .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter.')
      .matches(/[0-9]/, 'Password must contain at least one digit.')
      .matches(/[^a-zA-Z0-9]/, 'Password must contain at least one special character.')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter.'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm Password is required")
  });


  const onSubmit = async (values, { resetForm }) => {
    const formData = new FormData();
    formData.append("c_password", values.currentPassword);
    formData.append("new_password", values.newPassword);
    formData.append("customer_id", profile.customer_id);

    // Handle form submission
    try {
      const pData = await api("POST", "resetPassword", formData, true);
      if (pData.status === 'success') {
        resetForm();
        window.scrollTo(0, 0);
        onUpdate();
      } else {
        console.log('show error');
      }
      toast(pData.message);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="reset-password">
        <Formik
          initialValues={initialResetValues}
          validationSchema={validationResetSchema}
          onSubmit={onSubmit}
        >
          <Form noValidate autoComplete="off">
            <div className="form-item mb-2">
              <label htmlFor="currentPassword" className="form-label">
                Current Password
              </label>
              <Field
                type="password"
                className="form-control"
                id="currentPassword"
                name="currentPassword"
                autoComplete="off"
              />
              <ErrorMessage
                name="currentPassword"
                component="label"
                className="error"
              />
            </div>
            <div className="form-item mb-2">
              <label htmlFor="newPassword" className="form-label">
                New Password
              </label>
              <Field
                type="password"
                className="form-control"
                id="newPassword"
                name="newPassword"
                autoComplete="off"
              />
              <ErrorMessage
                name="newPassword"
                component="label"
                className="error"
              />
            </div>
            <div className="form-item mb-2">
              <label htmlFor="confirmPassword" className="form-label">
                Confirm Password
              </label>
              <Field
                type="password"
                className="form-control"
                id="confirmPassword"
                name="confirmPassword"
                autoComplete="off"
              />
              <ErrorMessage
                name="confirmPassword"
                component="label"
                className="error"
              />
            </div>
            <div className="mt-3 button-section cta-btn">
              <button type="submit" className="btn btn-primary btn-style">
                Submit
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </>
  );
}

export default ResetProfile;
