import React, { useState, useEffect } from "react";
import CustomerWelcome from "../components/common/CustomerWelcome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare, faKey, faClose
} from "@fortawesome/free-solid-svg-icons";
import ViewProfile from "../components/profile/ViewProfile";
import ResetProfile from "../components/profile/ResetProfile";
import EditProfile from "../components/profile/EditProfile";
import api from "../api/services";
import util from "../Utils";


function CProfile() {
  const [activeDiv, setActiveDiv] = useState(1);
  const [profileData, setProfileData] = useState([]);
  const showChangePassword = () => {
    setActiveDiv(2);
  };

  const showEditProfile = () => {
    setActiveDiv(3);
  };

  const showProfileDetails = () => {
    setActiveDiv(1);
  };


  const fetchUserDetails = async () => {
    try {
      const pData = await api("GET", "userDetails?id=" + util.customer_id, true);
      setProfileData(pData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);


  const handleProfileUpdate = (newData) => {
    // Update the profile data in the parent component
    setProfileData(newData);
    setActiveDiv(1);
  };
  const handlePasswordUpdate = (newData) => {
    setActiveDiv(1);
  };

  return (
    <React.Fragment>
      <div className="customer-content">
        <CustomerWelcome />
        <div className="customer-content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-head">
                    {activeDiv === 1 && <h4>My Profile details</h4>}
                    {activeDiv === 2 && <h4>Change my password</h4>}
                    {activeDiv === 3 && <h4>Edit Profile details</h4>}
                    {activeDiv !== 1 && <span className="cta-btn"><button className="btn btn-sm btn-style" onClick={showProfileDetails}><FontAwesomeIcon icon={faClose} /> Close</button></span>}
                    {activeDiv === 1 &&
                      <>
                        <span className="cta-btn"><button className="btn btn-sm btn-style" onClick={showChangePassword}><FontAwesomeIcon icon={faKey} /> Change password</button></span>
                        <span className="cta-btn"><button className="btn btn-sm btn-style" onClick={showEditProfile}><FontAwesomeIcon icon={faPenToSquare} /> Edit</button></span>
                      </>
                    }
                  </div>
                  <div className="card-body">
                    {activeDiv === 1 && <ViewProfile props={profileData} />}
                    {activeDiv === 2 && <ResetProfile profile={profileData} onUpdate={handlePasswordUpdate} />}
                    {activeDiv === 3 && <EditProfile profile={profileData} onUpdate={handleProfileUpdate} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CProfile;
