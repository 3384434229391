import React, { useEffect, useState } from "react";
import { IMG_BASE_URL } from "../../Helpers";
import { Link } from 'react-router-dom';

function ProductList(products) {
  const [product, setProducts] = useState([]);

  useEffect(() => {
    setProducts(products.props);
  }, [products]);

  return (
    <React.Fragment>
      <div className="product-list">
        <div className="row">
          {product?.map((item, index) => (
            <div className="col-lg-4 col-md-6 col-sm-12" key={index}>
              <div className="product-list-item">
                <div className="product-list-item-img text-center">
                  <picture>
                    <source srcset={IMG_BASE_URL + item.product_img + '_370x200.jpg'} media="(max-width: 480px)" />
                    <source srcset={IMG_BASE_URL + item.product_img + '_318x200.jpg'} media="(min-width: 481px) and (max-width: 1024px)" />
                    <source srcset={IMG_BASE_URL + item.product_img + '_251x200.jpg'} media="(min-width: 1024px)" />
                    <img src={IMG_BASE_URL + item.product_img + '_251x200.jpg'} alt={item.product_name} />
                  </picture>
                </div>
                <div className="product-list-item-detail">
                  <h3>{item.product_name}</h3>
                  <span>{item.product_code}</span>
                  <p>{item.product_one_line}</p>
                  <div className="product-list-item-detail-btn">
                    {item.product_filter_id === 3 ? (
                      <Link to={`/contact-us/`} className="btn btn-primary" state={{ subject: 'Enquire for ' + item.product_name }}>View More</Link>
                    ) : (
                      <Link to={`/offerings/${item.product_name}`} className="btn btn-primary" state={{ product_id: item.product_id,product_name:item.product_name }}>View More</Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
}

export default ProductList;
