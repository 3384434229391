import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import api from "../../api/services";

function EditProfile({ profile, onUpdate }) {
    const [initialValues, setInitialValues] = useState({
        full_name: profile.full_name,
        address: profile.address
    });

    const validationSchema = Yup.object({
        full_name: Yup.string().required("Full name is required"),
        address: Yup.string().required("Address is required")
    });

    const onSubmit = async (values, { resetForm }) => {
        const formData = new FormData();
        formData.append("full_name", values.full_name);
        formData.append("address", values.address);
        formData.append("customer_id", profile.customer_id);
        // Handle form submission
        try {
            const pData = await api("POST", "updateUserDetails", formData, true);
            if (pData.status === 'success') {
                resetForm();
                window.scrollTo(0, 0);
                onUpdate({
                    full_name: values.full_name,
                    address: values.address,
                    customer_id: profile.customer_id
                });
            } else {
                console.log('show error');
            }
            toast(pData.message);
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                <Form noValidate autoComplete="off">
                    <div className="form-item mb-2">
                        <label htmlFor="full_name" className="form-label">
                            Full Name
                        </label>
                        <Field
                            type="text"
                            className="form-control"
                            id="full_name"
                            name="full_name"
                            autoComplete="off"
                        />
                        <ErrorMessage
                            name="full_name"
                            component="label"
                            className="error"
                        />
                    </div>
                    <div className="form-item mb-2">
                        <label htmlFor="address" className="form-label">
                            Address
                        </label>
                        <Field
                            as="textarea"
                            className="form-control"
                            id="address"
                            name="address"
                            autoComplete="off"
                        />
                        <ErrorMessage
                            name="address"
                            component="label"
                            className="error"
                        />
                    </div>
                    <div className="mt-3 button-section cta-btn">
                        <button type="submit" className="btn btn-primary btn-style">
                            Submit
                        </button>
                    </div>
                </Form>
            </Formik>
        </>
    );
}

export default EditProfile;
