import React, { useState, useEffect } from "react";
import Filter from "../components/products/Filter";
import ProductList from "../components/products/ProductList";
import ReactPaginate from "react-paginate";

import api from "../api/services";

function Products() {
  const [productList, setProducts] = useState([]);
  const [products, setproductList] = useState([]);
  const [filterVal, setFilterVal] = useState(0);
  const [page, setPage] = useState(0);
  const productsPerPage = 6;
  const numberOfProductsVisited = page * productsPerPage;
  const totalPages = Math.ceil(products.length / productsPerPage);
  const [currentPage, setCurrentPage] = useState(0);
  const pr = [];

  const fetchProducts = async () => {
    try {
      const productsData = await api("GET", "products");
      setProducts(productsData);
      setproductList(productsData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    setCurrentPage(0); // Reset current page to 0 when filterVal changes
    setPage(0);
    if (filterVal > 0) {
      const filteredProduct = productList.filter(
        (x) => x.product_filter_id === filterVal
      );
      setproductList(filteredProduct);
    } else {
      setproductList(productList);
    }
  }, [productList, filterVal]);

  const FilterValue = (index) => {
    setFilterVal(index);
    setCurrentPage(0); // Set currentPage to 0 when FilterValue is triggered
    setPage(0); // Set page to 0 when FilterValue is triggered
  };

  const changePage = ({ selected }) => {
    setCurrentPage(selected);
    setPage(selected);
    document.querySelector('.products').scrollIntoView({ behavior: 'smooth' });
  };

  products
    .slice(numberOfProductsVisited, numberOfProductsVisited + productsPerPage)
    .forEach((pro) => {
      pr.push(pro);
    });

  const currentPageStart = currentPage * productsPerPage + 1;
  const currentPageEnd =
    (currentPage + 1) * productsPerPage > products.length
      ? products.length
      : (currentPage + 1) * productsPerPage;

  const pageRangeText = `${currentPageStart}-${currentPageEnd} of ${products.length} results`;

  return (
    <React.Fragment>
      <div className="container products">
        <div className="row pb-5">
          <div className="col-lg-3">
            <Filter FilterValue={FilterValue} />
          </div>
          <div className="col-lg-9 products-list">
            <div className="result-count">
              <span>{pageRangeText}</span>
            </div>
            <ProductList props={pr} />
            <div className="pagination">
              <ReactPaginate
                pageCount={totalPages}
                onPageChange={changePage}
                containerClassName={"navigationButtons"}
                previousLabel="< previous"
                nextLabel="next >"
                previousLinkClassName={"previousButton"}
                nextLinkClassName={"nextButton"}
                disabledClassName={"navigationDisabled"}
                activeClassName={"navigationActive"}
                previousClassName={products.length <= productsPerPage ? "pagination-hidden" : ""}
                nextClassName={products.length <= productsPerPage ? "pagination-hidden" : ""}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Products;
