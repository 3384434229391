import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CustomerWelcome from "../components/common/CustomerWelcome";
import api from "../api/services";
import DashCard from "../components/dashboard/DashCard";
import util from '../Utils';
import DashBtn from "../components/dashboard/DashBtn";

function Dashboard() {

  const location = useLocation();
  const [ticketCount, setTicketCount] = useState(0);
  const [activeTicketCount, setActiveTicketCount] = useState(0);
  const [closedTicketCount, setClosedTicketCount] = useState(0);
  const [UtilData, setUtilData] = useState();

  useEffect(() => {
    const passedValue = location.state && location.state.value;
    if (passedValue === 'fromLogin') {
      let authToken = sessionStorage.getItem('authToken');
      if (authToken) {
        const tokenPayload = authToken.split('.')[1];
        const decodedPayload = atob(tokenPayload);
        var userDetails = JSON.parse(decodedPayload);
        setUtilData(userDetails);
      }
    } else {
      setUtilData(util);
    }
  }, []);

  useEffect(() => {
    if (UtilData) {
      getCount("ticket");
      getCount("activeTicket");
      getCount("closedTicket");
    }
  }, [UtilData]);

  const getCount = async (key, url) => {
    try {
      if (UtilData) {
        switch (key) {
          case "ticket":
            const tCount = await api("GET", "getTicketCount?id=" + UtilData?.customer_id, true);
            setTicketCount(tCount);
            break;
          case "activeTicket":
            const atCount = await api("GET", "getTicketCount?id=" + UtilData?.customer_id + "&status=NEW", true);
            setActiveTicketCount(atCount);
            break;
          case "closedTicket":
            const cltCount = await api("GET", "getTicketCount?id=" + UtilData?.customer_id + "&status=DONE", true);
            setClosedTicketCount(cltCount);
            break;

          default:
            break;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <React.Fragment>
      <div className="customer-content">
        <CustomerWelcome />
        <div className="customer-content-body">
          <div className="container-fluid">
            <div className="row">
              {/* {productCount != '0' && <DashCard count={productCount} title="Total Products" icon="faBoxOpen" styleColor="bg-inverse-primary" navLinkTo="/c-products" />} */}
              {ticketCount !== '0' && <DashCard count={ticketCount} title="Total Tickets" icon="faTicket" styleColor="bg-inverse-warning" navLinkTo="/ticket" />}
              {activeTicketCount !== '0' && <DashCard count={activeTicketCount} title="Total Active Tickets" icon="faTicket" styleColor="bg-inverse-danger" navLinkTo="/ticket" />}
              {closedTicketCount !== '0' && <DashCard count={closedTicketCount} title="Total Closed Tickets" icon="faTicket" styleColor="bg-inverse-success" navLinkTo="/ticket" />}
            </div>
            <div className="row">
              <DashBtn title="Tickets" icon="faPlus" styleColor="bg-inverse-primary" navLinkTo="/ticket" />
              {/* <DashBtn title="My Products" icon="faBoxOpen" styleColor="bg-inverse-primary" navLinkTo="/c-products" /> */}
              <DashBtn title="My Profile" icon="faUser" styleColor="bg-inverse-primary" navLinkTo="/c-profile" />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Dashboard;
