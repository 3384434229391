import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCompassDrafting,
  faGear,
  faHandshake,
  faToolbox,
  faBuilding,
  faAward,
  faMapLocationDot,
  faMicrochip,
  faServer,
  faWandMagic,
  faTrophy,
  faMedal,
  faCalendarCheck
} from "@fortawesome/free-solid-svg-icons";

function Timeline() {
  return (
    <>
      <div className="container-fluid">
        <section className="ps-timeline-sec">
          <div className="timeline-container">
            <ol className="ps-timeline">
              <li>
                <div className="img-handler-top">
                  {/* <img
                  src="http://www.physology.co.uk/wp-content/uploads/2016/02/ps-elem_03.png"
                  alt=""
                /> */}
                  <p>2015</p>
                </div>
                <div className="ps-bot">
                  <p>
                    Company Incorporation
                  </p>
                </div>
                <span className="ps-sp-top">
                  <FontAwesomeIcon icon={faBuilding} />
                </span>
              </li>
              <li>
                <div className="img-handler-bot">
                  {/* <img
                  src="http://www.physology.co.uk/wp-content/uploads/2016/02/ps-elem_13.png"
                  alt=""
                /> */}
                  <p>2016</p>
                </div>
                <div className="ps-top">
                  <p>
                    Developed High Precision Motor Stages
                  </p>
                </div>
                <span className="ps-sp-bot">
                  <FontAwesomeIcon icon={faGear} />
                </span>
              </li>
              <li>
                <div className="img-handler-top">
                  {/* <img
                  src="http://www.physology.co.uk/wp-content/uploads/2016/02/ps-elem_05.png"
                  alt=""
                /> */}
                  <p>2016</p>
                </div>
                <div className="ps-bot">
                  <p>
                    Developed <em className="br"></em> 10 PPM DAQ
                  </p>
                </div>
                <span className="ps-sp-top">
                  <FontAwesomeIcon icon={faServer} />
                </span>
              </li>
              <li>
                <div className="img-handler-bot">
                  {/* <img
                  src="http://www.physology.co.uk/wp-content/uploads/2016/02/ps-elem_10.png"
                  alt=""
                /> */}
                  <p>2017</p>
                </div>
                <div className="ps-top">
                  <p>
                    Protoytpe Installed
                  </p>
                </div>
                <span className="ps-sp-bot">
                  <FontAwesomeIcon icon={faCompassDrafting} />
                </span>
              </li>
              <li>
                <div className="img-handler-top">
                  {/* <img
                  src="http://www.physology.co.uk/wp-content/uploads/2016/02/ps-elem_05.png"
                  alt=""
                /> */}
                  <p>2019</p>
                </div>
                <div className="ps-bot">
                  <p>
                    Signed CNDA with DRDO
                  </p>
                </div>
                <span className="ps-sp-top">
                  <FontAwesomeIcon icon={faHandshake} />
                </span>
              </li>
              <li>
                <div className="img-handler-bot">
                  {/* <img
                  src="http://www.physology.co.uk/wp-content/uploads/2016/02/ps-elem_10.png"
                  alt=""
                /> */}
                  <p>2020</p>
                </div>
                <div className="ps-top">
                  <p>
                    Developed <em className="br"></em> In-house Diamond and Boron Carbide Indenter
                  </p>
                </div>
                <span className="ps-sp-bot">
                  <FontAwesomeIcon icon={faToolbox} />
                </span>
              </li>
              <li>
                <div className="img-handler-top">
                  {/* <img
                  src="http://www.physology.co.uk/wp-content/uploads/2016/02/ps-elem_05.png"
                  alt=""
                /> */}
                  <p>2020</p>
                </div>
                <div className="ps-bot">
                  <p>
                    Installations - IIT, NIT, RRCAT, VTU, IISc
                  </p>
                </div>
                <span className="ps-sp-top">
                  <FontAwesomeIcon icon={faCalendarCheck} />
                </span>
              </li>
              <li>
                <div className="img-handler-bot">
                  {/* <img
                  src="http://www.physology.co.uk/wp-content/uploads/2016/02/ps-elem_10.png"
                  alt=""
                /> */}
                  <p>2022</p>
                </div>
                <div className="ps-top">
                  <p>
                    Developed Capacitive Sensor
                  </p>
                </div>
                <span className="ps-sp-bot">
                  <FontAwesomeIcon icon={faMicrochip} />
                </span>
              </li>
              <li>
                <div className="img-handler-top">
                  {/* <img
                  src="http://www.physology.co.uk/wp-content/uploads/2016/02/ps-elem_05.png"
                  alt=""
                /> */}
                  <p>2023</p>
                </div>
                <div className="ps-bot">
                  <p>
                    Co-branding <em className="br"></em>Snetly &#8482;
                  </p>
                </div>
                <span className="ps-sp-top">
                  <FontAwesomeIcon icon={faServer} />
                </span>
              </li>
              
              <li>
                <div className="img-handler-bot">
                  {/* <img
                  src="http://www.physology.co.uk/wp-content/uploads/2016/02/ps-elem_10.png"
                  alt=""
                /> */}
                  <p>2024</p>
                </div>
                <div className="ps-top">
                  <p>
                  Patented <em className="br"></em>Sensor Technology
                  </p>
                </div>
                <span className="ps-sp-bot">
                  <FontAwesomeIcon icon={faAward} />
                </span>
              </li>
            </ol>
          </div>
        </section>
      </div>
    </>
  );
}

export default Timeline;