import React from "react";
import { NavLink } from "react-router-dom";

function ProductTile() {

  const productTile = [{
    title: "FoundatiONE",
    content: "FoundatiONE helps you measure, monitor and analyze the material’s mechanical properties swiftly. FoundatiONE machine is the perfect tool for Micro, Macro and Nano instrumental Indentation test."
  }, {
    title: "Travix",
    content: "Whether you're conducting tensile test, compression test, cyclic loading tests or determining Young's Modulus, Travix can do it – all with a sleek, intuitive design."
  }, {
    title: "Nano indentation",
    content: "Our nanoindentation test enables accurate characterization of thin films, alloys for cutting tools and coatings for vairious purpose. Find out more..."
  }, {
    title: "CyclOp-X",
    content: "CyclOp-X testing system can determine the durability of any material, component or product, so you can rest assured that your products will last."
  }, {
    title: "LiteHAMMER",
    content: "Accurately measure co-efficient of friction to determine how two surfaces interact with each other, analyze wear resistance and sudden impact to evaluate the ability of the material to absorb shock."
  }, {
    title: "EVEP",
    content: "Introducing Nanatom's groundbreaking EV Real-Time Simulation and Emulation platform! This cutting-edge technology is revolutionizing the way we approach electric vehicle education."
  },]
  return (
    <>
      {productTile.map((product, index) => (
        <div className="product-list" key={index}>
          <div className="product-list-item">
            <h4 className="title-head">{product.title}</h4>
            <p className="">{product.content}</p>
          </div>
        </div>
      ))}
    </>
  );
}

export default ProductTile;
