import React from "react";

function CustomerLeft() {
  return (
    <React.Fragment>
      <p className=" para-long">
        Nanatom Technologies is dedicated to providing the best customer support
        possible. Whether you have a question about our products or services,
        need help troubleshooting an issue, or just want to provide feedback on
        your experience with us, we are here for you.
      </p>
      <p className=" para-long">
        Our team of experienced professionals is available to answer any
        questions or concerns you may have. We strive to make sure that every
        customer has a positive experience with us and that all their needs are
        met. Thanks for choosing Nanatom Technologies!
      </p>
    </React.Fragment>
  );
}

export default CustomerLeft;
