import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faJetFighterUp } from "@fortawesome/free-solid-svg-icons";

const ScrollToTop = () => {
  const { pathname } = useLocation();
  const [hideDiv, setHideDiv] = useState(false);

  useEffect(() => {
    scrollUp();
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      // Check if the scroll position is at the top
      if (scrollTop === 0) {
        setHideDiv(true);
      } else {
        setHideDiv(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [pathname]);

  function scrollUp() {
    window.scrollTo(0, 0);
    setHideDiv(true);
  }
  return (
    <>
      {!hideDiv && <div className='scrollToTop'
        onClick={scrollUp}>
        <FontAwesomeIcon icon={faAngleUp} style={{ color: "#169faf" }} size="2xl" />
      </div>
      }
    </>
  );
};

export default ScrollToTop;