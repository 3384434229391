import React, { useEffect, useState } from "react";

function CustomerWelcome() {
  const [userDetails, setUserDetails] = useState([]);

  useEffect(() => {
    let authToken = sessionStorage.getItem('authToken');

    const tokenPayload = authToken.split('.')[1];
    const decodedPayload = atob(tokenPayload);
    setUserDetails(JSON.parse(decodedPayload));
  }, []);

  return (
    <React.Fragment>
      <div className="customer-content-heading">
        <h3>Welcome <span className="heading-name">{userDetails.full_name}</span>,</h3>
      </div>
    </React.Fragment>
  );
}

export default CustomerWelcome;
