import React from "react";

function HomeSessionTwo() {
  return (
    <>
      <section className=" py-5 home-section-two">
        <div className="container py-md-5 py-4">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="position-relative">
                <h2 className="title-style">
                  Explore our Custom-Off-The-Shelf Products
                </h2>
              </div>
              <p className="">
                Nanatom being the hub of excellence for tribology and material
                technology, allowing us to construct machines to meet the
                specific requirements of each customer in compliance with ASTM
                and ISO Standards.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HomeSessionTwo;
