import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import api from "../../api/services";
import util from "../../Utils";
import { toast } from 'react-toastify';


function CreateTicket({ onUpdate }) {

  const categoryList = ['Enquiry', 'Feedback', 'Suggestion', 'Issues', 'Others'];
  const [feedbackStatus, setFeedbackStatus] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('Enquiry');

  const [options, setOptions] = useState([]);
  const fetchPurchaseDetails = async () => {
    try {
      const pData = await api("GET", "products", true);

      const formattedData = pData.map((element, index) => {
        return {
          value: element.product_id,
          label: element.product_name
        };
      });
      setOptions(formattedData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCategoryChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedCategory(selectedValue);
    if (selectedValue === 'Feedback' || selectedValue === 'Issues') {
      setFeedbackStatus(true);
    } else {
      setFeedbackStatus(false);
    }
  }

  useEffect(() => {
    fetchPurchaseDetails();
  }, []);
  const initialValues = {
    title: "",
    product_id: "",
    description: "",
    category: ""
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Title is required"),
    product_id: Yup.string().required("Please select a product"),
    description: Yup.string().required("Description is required"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    // Handle form submission
    const formData = new FormData();
    formData.append("product_id", values.product_id);
    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("customer_id", util.customer_id);
    formData.append("category", selectedCategory);
    // console.log(formData.category);
    try {
      const pData = await api("POST", "createTicket", formData, true);
      if (pData.status === 'success') {
        resetForm();
        window.scrollTo(0, 0);
        onUpdate();
      } else {
        console.log('show error');
      }
      toast(pData.message);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue }) => (
          <Form>
            <div className="form-item">
              <label htmlFor="title">Title</label>
              <Field type="text" id="title" name="title" className="form-control" />
              <ErrorMessage name="title" component="div" className="error" />
            </div>
            <div className="row mt-3">
              <div className="form-item col-lg-6">
                <label htmlFor="category">Category</label>
                <Field as="select" id="category" name="category" className="form-control" onChange={handleCategoryChange} value={selectedCategory}>
                  {categoryList.map((category, index) => (
                    <option key={index} value={category}>
                      {category}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="category" component="div" className="error" />
              </div>
              <div className="form-item col-lg-6">
                <label htmlFor="product">Select Product</label>
                <Field as="select" id="product_id" name="product_id" className="form-control">
                  <option value="">Select a product</option>
                  {options.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="product_id" component="div" className="error" />
              </div>
            </div>
            {feedbackStatus && <div className="row mt-3">
              <div className="form-item col-lg-6">
                <label htmlFor="invoice_number">Invoice Number</label>
                <Field type="text" id="invoice_number" name="invoice_number" className="form-control" />
                <ErrorMessage name="invoice_number" component="div" className="error" />
              </div>
              <div className="form-item col-lg-6">
                <label htmlFor="invoice_date">Invoice Date</label>
                <Field type="text" id="invoice_date" name="invoice_date" className="form-control" />
                <ErrorMessage name="invoice_date" component="div" className="error" />
              </div>
            </div>}
            <div className="form-item mt-3">
              <label htmlFor="description">Description</label>
              <ReactQuill
                id="description"
                name="description"
                // value={values.description}
                onChange={(content) => setFieldValue("description", content)}
                modules={{
                  toolbar: [
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["link", "image"],
                    ["clean"]
                  ]
                }}
                formats={[
                  "header",
                  "bold",
                  "italic",
                  "underline",
                  "strike",
                  "blockquote",
                  "list",
                  "bullet",
                  "link",
                  "image"
                ]}
              />
              <ErrorMessage name="description" component="div" className="error" />
            </div>

            <div className="mt-3 button-section cta-btn">
              <button type="submit" className="btn btn-primary btn-style btn-sm">Create Now</button>
            </div>
          </Form>
        )}
      </Formik>

    </React.Fragment>
  );
}

export default CreateTicket;
