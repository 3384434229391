import React, { useState, useEffect } from "react";
import { Outlet, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
// import { $ } from "react-jquery-plugin";

function CustomerHeader() {
  const [navbarOpen, setNavbarOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.pageYOffset;
      const header = document.getElementById('site-header');

      if (scroll >= 80) {
        header.classList.add('nav-fixed');
      } else {
        header.classList.remove('nav-fixed');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleNavLinkClick = () => {
    setNavbarOpen(false);
  };

  return (
    <React.Fragment>
      <header id="site-header" className="fixed-top customer-header">
        <nav
          className="navbar navbar-expand-lg navbar-light bg-light "
          id="site-header"
        >
          <div className="container">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => setNavbarOpen((prev) => !prev)}
            >
              {navbarOpen ? (
                <FontAwesomeIcon
                  icon={faXmark}
                  className="navbar-toggler-icon icon-close"
                  style={{ color: "#169faf" }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faBars}
                  className="navbar-toggler-icon icon-expand"
                  style={{ color: "#169faf" }}
                />
              )}
            </button>
            <div
              className={`collapse navbar-collapse ${navbarOpen ? 'show' : ''}`}
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-lg-auto">
                <li className="nav-item">
                  <NavLink
                    className={(navData) =>
                      navData.isActive ? "nav-link active" : "nav-link"
                    }
                    to="/"
                    onClick={handleNavLinkClick}
                  >
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={(navData) =>
                      navData.isActive ? "nav-link active" : "nav-link"
                    }
                    to="/about-us"
                    onClick={handleNavLinkClick}
                  >
                    About Us
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={(navData) =>
                      navData.isActive ? "nav-link active" : "nav-link"
                    }
                    to="/offerings"
                    onClick={handleNavLinkClick}
                  >
                    Offerings
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={(navData) =>
                      navData.isActive ? "nav-link active" : "nav-link"
                    }
                    to="/contact-us"
                    onClick={handleNavLinkClick}
                  >
                    Contact Us
                  </NavLink>
                </li>
                {/* <li className="nav-item">
                  <NavLink
                    className={(navData) =>
                      navData.isActive ? "nav-link active" : "nav-link"
                    }
                    to="/login"
                    onClick={handleNavLinkClick}
                  >
                    Customer Support
                  </NavLink>
                </li> */}
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <Outlet />
    </React.Fragment>
  );
}

export default CustomerHeader;
