import axios from "axios";
import { API_BASE_URL } from "../Helpers";
import { authToken, refreshToken } from "../Utils";

const api = (method, endpoint, data = null, Auth = false) => {
  const url = `${API_BASE_URL}/${endpoint}`;

  let headers = {
    "Content-Type": "text/plain"
  };
  if (Auth) {
    headers["Authorization"] = "Bearer" + sessionStorage.getItem('authToken');
  }

  // Show loader logic here
  const showLoader = () => {
    const loader = document.getElementById("loader");
    if (loader) {
      loader.style.display = "flex";
      document.body.style.overflow = "hidden";
    }
  };

  // Hide loader logic here
  const hideLoader = () => {
    const loader = document.getElementById("loader");
    if (loader) {
      loader.style.display = "none";
      document.body.style.overflow = "auto";
    }
  };

  showLoader();

  return axios({
    method,
    url,
    data,
    headers
  })
    .then((response) => {
      hideLoader();
      return response.data;
    })
    .catch((error) => {
      hideLoader();
      if (error.response && error.response.status === 401) {
        // Refresh authToken using refreshToken
        const formData = new FormData();
        formData.append('refreshToken', refreshToken);
        return axios.post(`${API_BASE_URL}/refreshToken`, formData)
          .then((response) => {
            if (response.data.status === "success") {
              sessionStorage.setItem('authToken', response.data.authToken);
              return api(method, endpoint, data, true);
            } else {
              // Unable to get new authToken, handle the error
              throw new Error('Unable to refresh authToken.');
            }
          })
          .catch((error) => {
            // Error while refreshing authToken, handle the error
            if(error.response && error.response.status === 403){
              sessionStorage.clear();
              window.location.href = "/login";
            }
            console.log(error);
            throw new Error('Error refreshing authToken.');
          });
      } else {
        // console.log("sercv",error.response.data)
        throw new Error(JSON.stringify(error.response.data));
        // return JSON.stringify(error.response.data);
      }
    });

};

export default api;
