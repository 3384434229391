import React from "react";

function HomeSessionSix() {
  return (
    <>
      <section className="" id="about">
        <div className="container py-md-5 py-4">
          <div className="row align-items-center">
            <div className="col-lg-6 ps-lg-5 mb-lg-0 mb-5 order-lg-first order-first">
              <div className="position-relative">
                <h2 className="title-style">
                  HTHT - High Temperature Hardness Testing Machine
                </h2>
              </div>
              <p className="">
                HTHT is a powerful tool for measuring the performance of
                materials and components under extreme temperatures and
                pressures. This testing can measure performance up to 1500°C and vacuum down to
                1x10<sup>-6</sup> Torr, allowing for the evaluation of materials in
                conditions that would otherwise be impossible to replicate.
              </p>
              <p>
                It can be used to assess the reliability, durability, and safety
                of a wide range of components, from automotive parts to
                aerospace components, so that manufacturers are able to make
                informed decisions about their design and production processes.
              </p>
            </div>
            <div className="col-lg-6 homeaboutblock order-lg-last order-last">
              <div className="position-relative img-border">
                <img
                  src={"/image/v2.jpg"}
                  className="img-fluid video-popup-image radius-image"
                  alt="video-popup"
                />
                {/* <picture>
                  <source serSet={"/image/v2.jpeg"} media="(min-width: 992px)" />
                  <source serSet={"/image/v2.jpeg"} media="(min-width: 768px)" />
                  <source serSet={"/image/v2.jpeg"} media="(min-width: 0px)" />
                  <img src={"/image/v2.jpeg"} width="1599" height="899" className="img-fluid video-popup-image radius-image" alt="Logo Img" />
                </picture> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HomeSessionSix;
