import React from "react";

import AboutSessionOne from "../components/about/AboutSessionOne";
import AboutSessionTwo from "../components/about/AboutSessionTwo";
import AboutSessionThree from "../components/about/AboutSessionThree";
import Timeline from "../components/about/Timeline";
// import Team from "../components/about/Team";

function About() {
  return (
    <React.Fragment>
      <div>
        <AboutSessionThree />
        <Timeline />
      </div>
    </React.Fragment>
  );
}

export default About;
