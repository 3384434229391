let authToken = sessionStorage.getItem('authToken');
const refreshToken = sessionStorage.getItem('refreshToken');
if(authToken){
    const tokenPayload = authToken.split('.')[1];
    const decodedPayload = atob(tokenPayload);
    var userDetails = JSON.parse(decodedPayload);
}

export { authToken, refreshToken };
export default userDetails;

