import React, { } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import CustomerLeft from "./CustomerLeft";
import { toast } from 'react-toastify';
import api from "../api/services";
import { useAuth } from "../components/common/Auth";


function Login() {
  const auth = useAuth();
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    username: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
  });

  const initialValues = {
    username: "",
    password: "",
  };

  const onSubmit = async (values, { resetForm }) => {
    const formData = new FormData();
    formData.append("username", values.username);
    formData.append("password", values.password);
    try {
      const loginData = await api("POST", "cLogin", formData);
      if (loginData.status === 'success') {

        const tokenPayload = loginData.authToken.split('.')[1];
        const decodedPayload = atob(tokenPayload);
        const userDetails = JSON.parse(decodedPayload);
        sessionStorage.setItem('authToken', loginData.authToken);
        sessionStorage.setItem('refreshToken', loginData.refreshToken);
        auth.login(userDetails.customer_id);
        navigate('/dashboard', { state: { value: 'fromLogin' }, replace: true });
        toast(loginData.message);
        setTimeout(() => {
          toast("Welcome " + userDetails.full_name);
        }, 1000);
      } else {
        toast(loginData.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <section className=" py-5 ">
        <div className="container">
          <div className="row login py-5 ">
            <div className="col-lg-6 col-md-12 py-5 login-left ">
              <CustomerLeft />
            </div>
            <div className="col-lg-6 col-md-12 py-5 login-form ">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                <Form noValidate autoComplete="off">
                  <div className="mb-3">
                    <h2>Customer Login</h2>
                  </div>
                  <div className="form-item mb-2">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Email
                    </label>
                    <Field
                      type="email"
                      className="form-control"
                      id="username"
                      name="username"
                      autoComplete="off"
                    />
                    <ErrorMessage
                      name="username"
                      component="label"
                      className="error"
                    />
                  </div>
                  <div className="form-item mb-2">
                    <label htmlFor="exampleInputPassword1" className="form-label">
                      Password
                    </label>
                    <Field
                      type="password"
                      className="form-control"
                      id="password"
                      name="password"
                      autoComplete="off"
                    />
                    <ErrorMessage
                      name="password"
                      component="label"
                      className="error"
                    />
                  </div>
                  <div className="mt-3 button-section">
                    <button type="submit" className="btn btn-primary login-btn">
                      Submit
                    </button>
                    {/* <span className="text-right register-link">
                      <p> Do not have an account?</p>
                      <p>
                        Click  to register.
                      </p>
                    </span> */}
                  </div>
                </Form>
              </Formik>
              <div className="register-link mt-4">
                <p>
                  <NavLink to="/forget-password">Forgot password?</NavLink>
                </p>
                <p>New user?
                  <NavLink to="/register"> Click here</NavLink> to register.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Login;
