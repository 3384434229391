import React, { useState, useEffect, useRef } from "react";
import { IMG_BASE_URL } from "../../Helpers";
import { NavLink, useLocation } from "react-router-dom";
import api from "../../api/services";


function ProductDetail() {
  const [product, setproduct] = useState({});

  const path = window.location.pathname;
  const parts = path.split('/');
  const urlPartAfterOfferings = parts[2] || '';
  const [productName, setProductName] = useState(urlPartAfterOfferings);

  const [specifications, setSpecifications] = useState([]);
  let { state } = useLocation();
  const specificationsRef = useRef(null);

  const [overview, setOverview] = useState(true);
  const [spec, setSpec] = useState(false);

  const showSpec = () => {
    setOverview(false);
    setSpec(true);
  };
  const showOverview = () => {
    setOverview(true);
    setSpec(false);
  };

  useEffect(() => {
    if (state) {
      setProductName(state.product_name);
    }
    const fetchProducts = async () => {
      try {
        const productsData = await api("GET", "productDetails?name=" + productName);
        setproduct(productsData);
        let spec = JSON.parse(productsData?.specifications);
        if (spec) {
          setSpecifications(spec.specifications);
        }
      } catch (error) {
        const errResp = JSON.parse(error.message);

        if (errResp) {
          if(errResp.status_code === 404){
            window.location.href = "/broken-url";
          }
        }
         else {
          console.log("No response property in the error object.");
        }
      }
    };
    fetchProducts();
  }, []);

  return (
    <React.Fragment>
      <div className="container">
        {product.product_img && (
          <div className="row">
            <div className="col-lg-12">
              <div className="productDetail">
                <div className="productDetail-header">
                  <h1>{product.product_name}</h1>
                  <p>{product.short_description}</p>
                </div>
                <div className="productDetail-body">
                  <div className="productDetail-body-links">
                    <a className={overview ? 'p-link btn active' : 'p-link btn'} onClick={showOverview}>
                      Overview
                    </a>
                    {specifications?.length > 0 && (
                      <a className={spec ? 'p-link btn active' : 'p-link btn'} onClick={showSpec}>
                        Specifications
                      </a>
                    )}
                  </div>
                  {overview && <div className="productDetail-body-item" id="overview">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="productDetail-body-item-header">
                          <h2>Overview</h2>
                        </div>
                        <div className="productDetail-body-item-body" dangerouslySetInnerHTML={{ __html: product.long_description }} >
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="productDetail-body-item-body">
                          <img
                            className="overview-img"
                            src={IMG_BASE_URL + product.product_img + '.jpg'}
                            alt=" "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  }
                  {specifications.length > 0 && spec && (
                    <div className="productDetail-body-item" id="specifications" >
                      <div className="productDetail-body-item-header">
                        <h2>Specifications</h2>
                      </div>
                      <div className="productDetail-body-item-body ">
                        <div className="row">
                          <div className="offset-2 col-lg-8 specifications">
                            <table className="specifications-table">
                              <tbody>
                                {specifications?.map((item, index) => (
                                  <tr key={index}>
                                    <th>{Object.keys(item)[0]}</th>
                                    <td dangerouslySetInnerHTML={{ __html: Object.values(item)[0] }}></td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          {/* <div className="col-lg-6 specifications">
                            <table className="specifications-table">
                              <tbody>
                                {table2?.map((item, index) => (
                                  <tr key={index}>
                                    <th>{Object.keys(item)[0]}</th>
                                    <td dangerouslySetInnerHTML={{ __html: Object.values(item)[0] }}></td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="right-action-btn">
        <NavLink to="/contact-us" >Request Demo</NavLink>
      </div>
    </React.Fragment>
  );
}

export default ProductDetail;
