import React from "react";
import { NavLink, useLocation } from "react-router-dom";

function InnerBanner() {
    let lastPageName = "Home";
    const location = useLocation();
    let pathnames = [{ name: "Home", path: "/" }];

    const paths = location.pathname
        .split("/")
        .filter((pathname) => pathname !== "");
    paths.forEach((element, index) => {
        if (index === 0) {
            element = element.replace(/-/g, ' ');
        }
        let pathname = {
            name: element,
            path: "/" + element.toLowerCase(),
        };
        pathnames.push(pathname);
    });
    lastPageName = pathnames[pathnames.length - 1].name;
    return (
        <React.Fragment >
            <section className="inner-banner py-5" >
                <div className="py-lg-5" >
                    <div className="container" >
                        <h1 className="inner-text-title font-weight-bold pt-sm-5 pt-4" > {lastPageName} </h1>
                        <div className="breadcrumbs-custom-path" >
                            {pathnames?.map((item, index) => (
                                <NavLink to={item.path} key={index} >
                                    <span key={index} > {item.name} </span>
                                </NavLink>
                            ))}
                        </div>
                    </div>
                    <div className="hero-overlay" > </div>
                </div>
                <div className="shape" >
                    <svg xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1440 280">
                        <path fillOpacity="1">
                            <animate attributeName="d"
                                dur="20000ms"
                                repeatCount="indefinite"
                                values="M0,160L48,181.3C96,203,192,245,288,261.3C384,277,480,267,576,234.7C672,203,768,149,864,117.3C960,85,1056,75,1152,90.7C1248,107,1344,149,1392,170.7L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z; M0,160L48,181.3C96,203,192,245,288,234.7C384,224,480,160,576,133.3C672,107,768,117,864,138.7C960,160,1056,192,1152,197.3C1248,203,1344,181,1392,170.7L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z;M0,64L48,74.7C96,85,192,107,288,133.3C384,160,480,192,576,170.7C672,149,768,75,864,80C960,85,1056,171,1152,181.3C1248,192,1344,128,1392,96L1440,64L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z;M0, 160 L48, 181.3 C96, 203, 192, 245, 288, 261.3 C384, 277, 480, 267, 576, 234.7 C672, 203, 768, 149, 864, 117.3 C960, 85, 1056, 75, 1152, 90.7 C1248, 107, 1344, 149, 1392, 170.7 L1440, 192 L1440, 320 L1392, 320 C1344, 320, 1248, 320, 1152, 320 C1056, 320, 960, 320, 864, 320 C768, 320, 672, 320, 576, 320 C480, 320, 384, 320, 288, 320 C192, 320, 96, 320, 48, 320 L0, 320 Z;">
                            </animate>
                        </path>
                    </svg>
                </div>
            </section>
        </React.Fragment>
    );
}

export default InnerBanner;