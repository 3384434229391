import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapLocationDot,
  faEnvelope,
  faPhoneVolume,
} from "@fortawesome/free-solid-svg-icons";
// import FooterShape from "./FooterShape";

function Footer() {
  return (
    <React.Fragment>
      <footer className="nanatom-footer">
        {/* <FooterShape /> */}
        <div className="nanatom-footer-sub">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 nanatom-footer-sub-left">
                <div className="nanatom-footer-sub-left-item">
                  <span className="footer-icon">
                    <FontAwesomeIcon icon={faMapLocationDot} />
                  </span>
                  <span>
                    <p>
                      Nanatom Technologies Pvt. Ltd.
                      <br />
                      #67/21, SM Greens, Ground Floor,
                      <br />
                      Muthyalamma Layout,
                      <br />
                      Kuvempunagar, 1st Stage,
                      <br />
                      Jalahalli East, Bangalore-560014
                      <br />
                      <br />
                      <br />
                      <br />
                    </p>
                  </span>
                </div>
                <div className="nanatom-footer-sub-left-item">
                  <span className="footer-icon">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </span>
                  <span>
                    <p>sales@nanatom.in</p>
                    <p>works@nanatom.in</p>
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                </div>
                <div className="nanatom-footer-sub-left-item">
                  <span className="footer-icon">
                    <FontAwesomeIcon icon={faPhoneVolume} />
                  </span>
                  <span>
                    <p>+91 968 619 4812</p>
                    <p>+91 990 000 2037</p>
                  </span>
                </div>
              </div>
              <div className="col-lg-6">
                <iframe
                  className="gmap-iframe"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.4319162879397!2d77.53082657591781!3d13.071790512672063!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae17e745510911%3A0xbcdc4b028be3ed93!2sNanatom%20Technologies!5e0!3m2!1sen!2sin!4v1682755514767!5m2!1sen!2sin"
                  width="100%"
                  height="450"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="map"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        <div className="nanatom-footer-copyright text-center">
          <div className="container">
            <p className="CopyRights">
              © 2023 Nanatom. All Rights Reserved | Design By <a className="designer" href="https://in.linkedin.com/in/raru-chempazhanthy-43549623">Raru</a>
            </p>
          </div>
        </div>
        {/* <div className="container-fluid">
                    <div className="row">
                        <div className="">

                        </div>
                    </div>
                </div> */}
      </footer>
    </React.Fragment>
  );
}

export default Footer;
