import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxOpen, faTicket } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";

function DashCard({ count, title, icon, styleColor, navLinkTo }) {
  const renderIcon = () => {
    if (icon === "faBoxOpen") {
      return <FontAwesomeIcon className="mdi mdi-account-group" icon={faBoxOpen} />;
    } else {
      return <FontAwesomeIcon icon={faTicket} />;
    }
  };
  return (
    <React.Fragment>
      <div className="col-3 dash">
        <div className="dash-item d-flex flex-column align-items-center justify-content-center">
          <div className="dash-item-body text-center">
            <div className={`profile-img img-rounded no-avatar component-flat mx-auto mb-2 ${styleColor}`}>
              {renderIcon()}
            </div>
            <h2 className="font-weight-medium"><span className="animated-count">{count}</span></h2>
            <NavLink to={navLinkTo} title="view Details">
              <small className="text-gray d-block mt-1">{title}</small>
            </NavLink>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default DashCard;
