import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import Loader from './api/loader';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Loader />
    <ToastContainer />
    <App />
  </React.StrictMode>
);
