import React from "react";

function HomeSessionSeven() {
  return (
    <>
      <section className="" id="about">
        <div className="container py-md-5 py-4">
          <div className="row align-items-center">
            <div className="col-lg-6 homeaboutblock order-lg-first order-last">
              <div className="position-relative img-border">
                <img
                  src={"/image/v3.jpg"}
                  className="img-fluid video-popup-image radius-image"
                  alt="video-popup"
                />
                {/* <picture>
                  <source serSet={"/image/v1.jpeg"} media="(min-width: 992px)" />
                  <source serSet={"/image/v1.jpeg"} media="(min-width: 768px)" />
                  <source serSet={"/image/v1.jpeg"} media="(min-width: 0px)" />
                  <img src={"/image/v1.jpeg"} width="1599" height="899" className="img-fluid video-popup-image radius-image" alt="Logo Img" />
                </picture> */}
              </div>
            </div>
            <div className="col-lg-6 ps-lg-5 mb-lg-0 mb-5 order-lg-last order-first">
              <div className="position-relative">
                <h2 className="title-style">Turnkey Solutions by Nanatom</h2>
              </div>
              <p className="">
                We help simplify the process of finding the right lab setup,
                materials, and machines for your research project. Our
                consultancy services provide tailored guidance to select the
                most appropriate equipment, materials, and lab facilities for
                your specific project needs.
              </p>
              <p>
                We provide customized solutions for your unique research needs.
                Our team offers support and expertise from planning to ongoing
                maintenance, ensuring you achieve your research goals with
                confidence.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HomeSessionSeven;
