import React, { useEffect, useState } from "react";
import CustomerWelcome from "../components/common/CustomerWelcome";
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import { faEye, faPlus, faTicket } from "@fortawesome/free-solid-svg-icons";

import api from "../api/services";
import util from "../Utils";
import moment from 'moment';



function CProductDetail() {


  // useEffect(() => {
    // fetchPurchaseDetails();
  // }, []);
  
  return (
    <React.Fragment>
      <div className="customer-content">
        <CustomerWelcome />
        <div className="customer-content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-head">
                    My product details
                  </div>
                  <div className="card-body">

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CProductDetail;
