import React from "react";

function ShortFooter() {
  return (
    <React.Fragment>
      <footer className="nanatom-footer contact-footer">
        <div className="nanatom-footer-copyright text-center">
          <div className="container">
            <p className="CopyRights">
              © 2023 Nanatom.All Rights Reserved | Design By Raru
            </p>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
}

export default ShortFooter;
