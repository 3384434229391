import React from 'react';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import './index.css';
import './Style/Style.scss';

import ScrollToTop from './components/common/ScrollToTop';
import routes from './components/common/Routes';
import { AuthProvider } from './components/common/Auth';
import { RequireAuth } from './components/common/RequireAuth';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <AuthProvider>
        <Routes>
          {routes.map((route, index) => (
            <Route path={route.path} key={index} index element={
              <>
                {route.header && <route.header />}
                {route.innerBanner && <route.innerBanner />}
                {route.leftSide && <route.leftSide />}
                {route.auth ? <RequireAuth> <route.component /> </RequireAuth> : <route.component />}
                {route.footer && <route.footer />}
              </>
            } />
          ))}
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
reportWebVitals();