import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxOpen, faGauge, faRightFromBracket, faTicket, faUser } from "@fortawesome/free-solid-svg-icons";

function LeftSide() {

  const logout = () => {
    sessionStorage.clear();
  };
  return (
    <React.Fragment>
      <aside>
        <a href="index3.html" className="brand-link">
          <img src="/image/logo.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" />
        </a>
        <div className="sidebar">
          <div className="sidebar_inner">
            <nav className="mt-2">
              <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                <li className="nav-item">
                  <NavLink
                    className={(navData) =>
                      navData.isActive ? "nav-link active" : "nav-link"
                    }
                    to="/dashboard"
                  >
                    <FontAwesomeIcon icon={faGauge} />
                    <p>
                      Dashboard
                    </p>
                  </NavLink>
                </li>
                {/* <li className="nav-item">
                  <NavLink
                    className={(navData) =>
                      navData.isActive ? "nav-link active" : "nav-link"
                    }
                    to="/c-products"
                  >
                    <FontAwesomeIcon icon={faBoxOpen} />
                    <p>
                      Products
                    </p>
                  </NavLink>
                </li> */}
                <li className="nav-item">
                  <NavLink
                    className={(navData) =>
                      navData.isActive ? "nav-link active" : "nav-link"
                    }
                    to="/ticket"
                  >
                    <FontAwesomeIcon icon={faTicket} />
                    <p>
                      Tickets
                    </p>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={(navData) =>
                      navData.isActive ? "nav-link active" : "nav-link"
                    }
                    to="/c-profile"
                  >
                    <FontAwesomeIcon icon={faUser} />
                    <p>
                      My Profile
                    </p>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    onClick={logout}
                    to="/login"
                  >
                    <FontAwesomeIcon icon={faRightFromBracket} />
                    <p>
                      Logout
                    </p>
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </aside>
    </React.Fragment>
  );
}

export default LeftSide;
