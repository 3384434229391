import React from 'react';
import Header from '../../components/common/Header';
import InnerBanner from '../../components/common/InnerBanner';
import ProductDetail from '../../components/products/ProductDetail';
import Home from '../../pages/Home';
import About from '../../pages/About';
import NoPage from '../../pages/NoPage';
import Contact from '../../pages/Contact';
import Products from '../../pages/Products';
import Register from '../../pages/Register';
import Login from '../../pages/Login';
import Footer from './Footer';

import CustomerHeader from './CustomerHeader';
import LeftSide from './LeftSide';
import CustomerFooter from './CustomerFooter';

import Dashboard from '../../pages/Dashboard';
import Tickets from '../../pages/Tickets';
import ForgotPassword from '../../pages/ForgotPassword';
import CProducts from '../../pages/CProducts';
import CProfile from '../../pages/CProfile';
import CProductDetail from '../../pages/CProductDetail';
import TicketDetails from '../ticket/TicketDetails';
import Manuals from '../../pages/Manuals';


const routes = [
  {
    path: '/',
    component: Home,
    header: Header,
    footer: Footer,
    auth: false,
    innerBanner: null,
  },
  {
    path: 'offerings',
    component: Products,
    header: Header,
    footer: Footer,
    auth: false,
    innerBanner: InnerBanner,
  },
  {
    path: 'offerings/:productId',
    component: ProductDetail,
    header: Header,
    footer: Footer,
    auth: false,
    innerBanner: InnerBanner,
  },
  {
    path: 'about-us',
    component: About,
    header: Header,
    footer: Footer,
    auth: false,
    innerBanner: InnerBanner,
  },
  {
    path: 'contact-us',
    component: Contact,
    header: Header,
    footer: null,
    auth: false,
    innerBanner: InnerBanner,
  },
  {
    path: 'login',
    component: Login,
    header: Header,
    footer: Footer,
    auth: false,
    innerBanner: null,
  },
  {
    path: 'register',
    component: Register,
    header: Header,
    footer: Footer,
    auth: false,
    innerBanner: null,
  },
  {
    path: 'forget-password',
    component: ForgotPassword,
    header: Header,
    footer: Footer,
    auth: false,
    innerBanner: null,
  },
  {
    path: 'dashboard',
    component: Dashboard,
    header: CustomerHeader,
    footer: CustomerFooter,
    leftSide: LeftSide,
    auth: true,
    innerBanner: null,
  },
  {
    path: 'ticket',
    component: Tickets,
    header: CustomerHeader,
    footer: CustomerFooter,
    leftSide: LeftSide,
    auth: true,
    innerBanner: null,
  },
  {
    path: 'c-profile',
    component: CProfile,
    header: CustomerHeader,
    footer: CustomerFooter,
    leftSide: LeftSide,
    auth: true,
    innerBanner: null,
  },
  {
    path: 'c-ticketDetail/:ticketId',
    component: TicketDetails,
    header: CustomerHeader,
    footer: CustomerFooter,
    leftSide: LeftSide,
    auth: true,
    innerBanner: null,
  },
  // {
  //   path: 'c-products',
  //   component: CProducts,
  //   header: CustomerHeader,
  //   footer: CustomerFooter,
  //   leftSide: LeftSide,
  //   auth: true,
  //   innerBanner: null,
  // },
  // {
  //   path: 'c-productDetail',
  //   component: CProductDetail,
  //   header: CustomerHeader,
  //   footer: CustomerFooter,
  //   leftSide: LeftSide,
  //   auth: true,
  //   innerBanner: null,
  // },
  {
    path: 'broken-url',
    component: NoPage,
    header: Header,
    footer: Footer,
    auth: false,
    innerBanner: null,
  },
  {
    path: '*',
    component: NoPage,
    header: Header,
    footer: Footer,
    auth: false,
    innerBanner: null,
  },
  // {
  //   path: 'manuals/:productId',
  //   component: Manuals,
  //   header: Header,
  //   footer: Footer,
  //   auth: false,
  //   innerBanner: InnerBanner,
  // },
];

export default routes;