import React from "react";
import { NavLink } from "react-router-dom";

function HomeSessionOne() {
  return (
    <>
      <section className=" py-md-5 py-4">
        <div className="container ">
          <div className="row">
            <div className="col-lg-6 pe-lg-5">
              <div className="position-relative">
                <h1 className="title-style">IP granted sensing technology</h1>
              </div>
              <p className=" ">
                Discover the power of real-time analytics and precision with our patented sensing technology!
              </p>
              <div className="d-flex">
                <p className="">
                  Our machine offers a variety of features that will help you get
                  the highest precision for your data and analytics. You can also
                  access advanced diagnostics and analytics to understand and
                  improve your results. Plus, with real-time data and insights,
                  you’ll be able to make decisions quickly and accurately.
                </p>
                <img
                    src={"/image/patent.png"}
                    className="img-fluid patent-img radius-image"
                    alt="video-popup"
                  />
              </div>
              <p className="">
                Nanatom's machines provide researchers and scientists with the
                tools to learn about materials and extract vital parameters.
              </p>
            </div>
            <div className="col-lg-6 pe-lg-5">
              <div className="position-relative">
                <h1 className="title-style"> Stay ahead of the curve</h1>
              </div>
              <p className=" ">
                As a special purpose machine manufacturer, Nanatom has built a
                strong reputation for delivering high-quality, innovative
                products that are designed to meet the unique needs of customers
                across a variety of industries.
              </p>
              <p className="">
                By combining deep technical expertise with a commitment to
                customer service, Nanatom is well-positioned to continue driving
                innovation and delivering value to customers in the years ahead.
              </p>
              <p>
                Nanatom is making significant strides in developing new
                solutions that enhance the efficiency and accuracy of testing
                processes.
              </p>
            </div>
            <div className="col-lg-12 pe-lg-5 more-btn-wrapper cta-btn">
              <NavLink className="btn btn-style" to="/about-us"> Discover More</NavLink>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HomeSessionOne;
