import React, { useState } from "react";

function Filter({ FilterValue }) {
  const [activeIndex, setActiveIndex] = useState(0);

  const addclass = (index) => {
    setActiveIndex(index);
  };

  const getListClass = (index) => {
    if (index === activeIndex) {
      return "filter-items-item activeFilter";
    } else {
      return "filter-items-item";
    }
  };
  return (
    <React.Fragment>
      <div className="filter">
        <div className="filter-heading">
          <h3>Filter</h3>
        </div>
        <div className="filter-items">
          <span
            className={getListClass(0)}
            onClick={() => {
              addclass(0);
              FilterValue(0);
            }}
          >
            All Offerings
          </span>
          <span
            className={getListClass(1)}
            onClick={() => {
              addclass(1);
              FilterValue(1);
            }}
          >
            COTS
            <i>(Custom-Off-The-Shelf)</i>
          </span>
          <span
            className={getListClass(2)}
            onClick={() => {
              addclass(2);
              FilterValue(2);
            }}
          >
            SCOTS
            <i>(Specialized-Custom-Off-The-Shelf)</i>
          </span>
          <span
            className={getListClass(3)}
            onClick={() => {
              addclass(3);
              FilterValue(3);
            }}
          >
            Consultation
          </span>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Filter;
