import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import api from "../api/services";

function Manuals() {
  const path = window.location.pathname;
  const parts = path.split('/');
  const urlPartAfterOfferings = parts[2] || '';
  
  let { state } = useLocation();
  const [productName, setProductName] = useState(urlPartAfterOfferings);
  const [manual, setManual] = useState([]);
  const borderColors = ['#337ab7', '#e74c3c', '#f39c12', '#2ecc71', '#9b59b6'];

  useEffect(() => {
    const newProductName = state?.product_name || urlPartAfterOfferings;
    if (newProductName !== productName) {
      setProductName(newProductName);
    }

    const fetchManuals = async () => {
      try {
        const manualData = await api("GET", `getManuals?page_title=${newProductName}`);
        if (manualData && manualData.pdf_list) {
          const manualArray = JSON.parse(manualData.pdf_list);
          setManual(manualArray);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchManuals(); 
  }, [state, urlPartAfterOfferings]);

  const handleDownload = async (filename) => {
    try {
      const apiUrl = `https://api.nanatom.in/downloadManual?filename=` + filename;
      const response = await fetch(apiUrl);

      if (!response.ok) {
        throw new Error('Failed to download file');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      link.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Download error:', error);
    }
  };

  return (
    <div className="container-fluid">
      <section className="py-md-5 py-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 pe-lg-5">
              <div className="position-relative">
                <h1 className="title-style">Download the required files</h1>
                {manual.map((item, index) => (
                  <div key={index} className="download-box" style={{ borderLeft: `4px solid ${borderColors[index % borderColors.length]}` }}>
                    {item.name}
                    <div className="more-btn-wrapper cta-btn">
                      <button className="btn btn-style" onClick={() => handleDownload(item.filename)}>Download</button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Manuals;
