import React, { useEffect, useState } from "react";

import CustomerWelcome from "../components/common/CustomerWelcome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import { faClose, faEye, faPlus } from "@fortawesome/free-solid-svg-icons";

import api from "../api/services";
import util from "../Utils";
import TicketList from "../components/ticket/TicketList";
import CreateTicket from "../components/ticket/CreateTicket";


const columns = [
  {
    name: 'Sl No',
    selector: row => row.slno,
    sortable: true,
  },
  {
    name: 'Ticket Number',
    selector: row => row.ticket_number,
  },
  {
    name: 'Product Name',
    selector: row => row.product_name,
  },
  {
    name: 'Status',
    selector: row => row.status,
  },
  {
    name: '',
    selector: row => row.action,
  },
];


function Tickets() {
  const [activeDiv, setActiveDiv] = useState(1);


  const createTicket = () => {
    setActiveDiv(2);
  };
  const goToList = () => {
    setActiveDiv(1);
  };


  const [ticketData, setTicketData] = useState([]);
  const fetchPurchaseDetails = async () => {
    try {
      const pData = await api("GET", "getMyTickets?id=" + util.customer_id, true);

      const formattedData = pData.map((element, index) => {
        return {
          slno: index + 1,
          ticket_number: element.ticket_number,
          product_name: element.product_name,
          // bill_no: element.bill_no,
          status: element.status,
          action: <><NavLink to={`/c-ticketDetail/${element.ticket_id}`} title="view Details"><FontAwesomeIcon icon={faEye} /> View Details</NavLink></>
        };
      });
      setTicketData(formattedData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPurchaseDetails();
  }, []);

  const handlecreated = (newData) => {
    setActiveDiv(1);
    fetchPurchaseDetails();
  };
  return (
    <React.Fragment>
      <div className="customer-content">
        <CustomerWelcome />
        <div className="customer-content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-head">

                    {activeDiv === 1 ?
                      <>
                        <h4>My tickets</h4>
                        <span className="cta-btn">
                          <button className="btn btn-sm btn-style" onClick={createTicket}>
                            <FontAwesomeIcon icon={faPlus} /> Create Ticket
                          </button>
                        </span>
                      </>
                      :
                      <>
                        <h4>Create new ticket</h4>
                        <span className="cta-btn">
                          <button className="btn btn-sm btn-style" onClick={goToList}>
                            <FontAwesomeIcon icon={faClose} /> Close
                          </button>
                        </span>
                      </>
                    }

                  </div>
                  <div className="card-body">

                    {activeDiv === 1 && <TicketList columns={columns} ticketData={ticketData} />}
                    {activeDiv === 2 && <CreateTicket onUpdate={handlecreated}/>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Tickets;
