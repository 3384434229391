import React, { children, createContext, useContext, useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import userDetails from "../../Utils";

const AuthContext = createContext(null);


export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState(null);

  const redirectPath = location.state?.path || '/dashboard';
  const login = (user) => {
    setUser(user);
  }
  const logout = user => {
    setUser(null);
  }

  useEffect(() => {
    if (sessionStorage.getItem('authToken')) {
      setUser(userDetails.customer_id);
      navigate(redirectPath, { replace: true });
    }
  }, []);


  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(AuthContext)
}

