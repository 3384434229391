import React from "react";
import Carousel from "../components/home/Carousel";
import HomeSessionOne from "../components/home/HomeSessionOne";
import HomeSessionTwo from "../components/home/HomeSessionTwo";
import HomeSessionThree from "../components/home/HomeSessionThree";
import HomeSessionFour from "../components/home/HomeSessionFour";
import HomeSessionFive from "../components/home/HomeSessionFive";
import HomeSessionSix from "../components/home/HomeSessionSix";
import HomeSessionSeven from "../components/home/HomeSessionSeven";
import HomeSessionEight from "../components/home/HomeSessionEight";
import ClientLogos from "../components/home/ClientLogos";

function Home() {
  return (
    <React.Fragment>
      <Carousel />
      <HomeSessionOne />
      <HomeSessionTwo />
      <HomeSessionThree />
      <HomeSessionFour />
      <HomeSessionFive />
      <HomeSessionSix />
      <HomeSessionSeven />
      <HomeSessionEight />
      <ClientLogos />
    </React.Fragment>
  );
}

export default Home;
