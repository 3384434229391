import React from "react";
import { Link } from 'react-router-dom';

function HomeSessionEight() {
  return (
    <>
      <section className="home-session-four" id="services">
        <div className="container pb-lg-5 pb-4">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-12">
              <div className="area-box h-100">
                <i className="fas fa-hand-holding-heart"></i>
                <h4>
                  <a href="#" className="title-head">
                    How to buy our Products?
                  </a>
                </h4>
                <p className="">
                  Follow our step-by-step guide to make the process as easy and
                  stress-free as possible. <Link to={`/contact-us`} className="click-here" >Click Here</Link>
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 mt-lg-0 mt-4">
              <div className="area-box h-100">
                <i className="fas fa-paw"></i>
                <h4>
                  <a href="#" className="title-head">
                    Updates and downloads
                  </a>
                </h4>
                <p className="">
                  Get the latest features and bug fixes you need to stay ahead
                  of the competition. <Link to={`/login`} className="click-here" > Login</Link> to get the files.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 mt-lg-0 mt-4">
              <div className="area-box">
                <i className="fas fa-dog"></i>
                <h4>
                  <a href="#" className="title-head">
                    Career @ Nanatom
                  </a>
                </h4>
                <p className="">
                  We’re looking for motivated, driven individuals who want to
                  make a real impact. So if you’re ready to take the leap and
                  <Link to={`/contact-us`} className="click-here" > join us</Link>, now’s the time!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HomeSessionEight;
