import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';



function TicketList({ columns, ticketData }) {

  return (
    <React.Fragment>

      <DataTable
        columns={columns}
        data={ticketData}
        pagination
        responsive
      />

    </React.Fragment>
  );
}

export default TicketList;
