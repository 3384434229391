import React from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapLocationDot,
  faEnvelope,
  faPhoneVolume,
} from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import api from "../api/services";
import { toast } from 'react-toastify';
import ShortFooter from "../components/common/ShortFooter";

const Contact = () => {
  const location = useLocation();

  const validationSchema = Yup.object({
    full_name: Yup.string().required("Full Name is required"),
    email: Yup.string().email("Invalid email address").required("Email is required"),
    phone: Yup.string().required("Phone Number is required").matches(/^\d{10,13}$/, "Invalid phone number"),
    subject: Yup.string().required("Subject is required"),
    message: Yup.string().required("Message is required"),
  });

  const initialValues = {
    full_name: "",
    email: "",
    phone: "",
    subject: "",
    message: ""
  };

  const onSubmit = async (values, { resetForm }) => {
    const formData = new FormData();
    formData.append("full_name", values.full_name);
    formData.append("email", values.email);
    formData.append("phone", values.phone);
    formData.append("subject", values.subject);
    formData.append("message", values.message);
    try {
      const contactData = await api("POST", "contactForm", formData);
      if (contactData.status === 'success') {
        resetForm();
        window.scrollTo(0, 0);
        toast("Contact form submitted successfully!");
      } else {
        console.log('show error');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  if (location.state) {
    const { subject } = location.state;
    console.log(subject);
    formik.values.subject = subject;
  }

  return (
    <React.Fragment>
      <div className="contact-info pt-5" id="contact">
        <div className="container">
          <div className="row contact-info-top">
            {/* <div className="col-lg-6">
              <div className="row contact-items address">
                <div className="item">
                  <FontAwesomeIcon icon={faMapLocationDot} size="2xl" />
                </div>
                <div className="item">
                  <h4 className="">Address info</h4>
                  <p>
                    Nanatom Technologies Pvt. Ltd. #67/21, SM Greens, Ground Floor, Muthyalamma Layout, Kuvempunagar,1st Stage, Gangammagudi Police Station Road, Jalahalli East, Bangalore-560014
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 contact-info-top-right">
              <div className="row contact-items email">
                <div className="item">
                  <FontAwesomeIcon icon={faEnvelope} size="2xl" />
                </div>
                <div className="item">
                  <h4 className="">Email</h4>
                  <p className="">
                    <a href="mailto:sales@nanatom.in">sales@nanatom.in</a>
                  </p>
                  <p className="">
                    <a href="mailto:works@nanatom.in">works@nanatom.in</a>
                  </p>
                </div>
              </div>
              <div className="row contact-items">
                <div className="item">
                  <FontAwesomeIcon icon={faPhoneVolume} size="2xl" />
                </div>
                <div className="item">
                  <h4 className="">Contact Number</h4>
                  <p className="">
                    <a href="tel:+91-1234567890">+91-1234567890</a>
                  </p>
                  <p className="">
                    <a href="tel:+91-0987654321">+91-0987654321</a>
                  </p>
                </div>
              </div>
            </div> */}
          </div>
          <div className="row mt-5 pt-md-5 contact-info-left">
            <div className="col-lg-6 map order-lg-1 order-2">

              <div className="">
                <div className="row contact-items address">
                  <div className="item">
                    <FontAwesomeIcon icon={faMapLocationDot} size="2xl" />
                  </div>
                  <div className="item">
                    <h4 className="">Address info</h4>
                    <p>
                      Nanatom Technologies Pvt. Ltd. #67/21, SM Greens, Ground Floor, Muthyalamma Layout, Kuvempunagar, 1st Stage, Jalahalli East, Bangalore-560014
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 contact-info-top-right">
                <div className="row contact-items email">
                  <div className="item">
                    <FontAwesomeIcon icon={faEnvelope} size="2xl" />
                  </div>
                  <div className="item">
                    <h4 className="">Email</h4>
                    <p className="">
                      <a href="mailto:sales@nanatom.in">sales@nanatom.in</a>
                    </p>
                    <p className="">
                      <a href="mailto:works@nanatom.in">works@nanatom.in</a>
                    </p>
                  </div>

                </div>
                
                <div className="row contact-items">
                  <div className="item">
                    <FontAwesomeIcon icon={faPhoneVolume} size="2xl" />
                  </div>
                  <div className="item">
                    <h4 className="">Contact Number</h4>
                    <p className="">
                      <a href="tel:+919686194812">+91 968 619 4812</a>
                    </p>
                    <p className="">
                      <a href="tel:+919900002037">+91 990 000 2037</a>
                    </p>
                  </div>
                </div>
              </div>
              <iframe
                className="gmap-iframe"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.4319162879397!2d77.53082657591781!3d13.071790512672063!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae17e745510911%3A0xbcdc4b028be3ed93!2sNanatom%20Technologies!5e0!3m2!1sen!2sin!4v1682755514767!5m2!1sen!2sin"
                width="100%"
                height="250"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="map"
              ></iframe>
            </div>
            <div className="col-lg-6 form-inner-cont order-lg-2 order-1 mb-lg-0 mb-5 ps-lg-4 contact-info-right">
              <form
                className="signin-form"
                noValidate
                autoComplete="off"
                onSubmit={formik.handleSubmit}
              >
                <div className="form-item mb-2">
                  <label htmlFor="" className="form-label">
                    Full Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="full_name"
                    name="full_name"
                    aria-describedby=""
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.full_name}
                    autoComplete="off"
                  />
                  {formik.touched.full_name && formik.errors.full_name && (
                    <label className="error">{formik.errors.full_name}</label>
                  )}
                </div>
                <div className="form-item mb-2">
                  <label htmlFor="" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    aria-describedby="emailHelp"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <label className="error">{formik.errors.email}</label>
                  )}
                </div>
                <div className="form-item mb-2">
                  <label htmlFor="" className="form-label">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    className="form-control"
                    id="phone"
                    name="phone"
                    aria-describedby="phoneHelp"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                  />
                  {formik.touched.phone && formik.errors.phone && (
                    <label className="error">{formik.errors.phone}</label>
                  )}
                </div>
                <div className="form-item mb-2">
                  <label htmlFor="" className="form-label">
                    Subject
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="subject"
                    name="subject"
                    aria-describedby=""
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.subject}
                  />
                  {formik.touched.subject && formik.errors.subject && (
                    <label className="error">{formik.errors.subject}</label>
                  )}
                </div>
                <div className="form-item mb-2">
                  <label htmlFor="" className="form-label">
                    Message
                  </label>
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    placeholder=""
                    required=""
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.message}
                  >
                  </textarea>
                  {formik.touched.message && formik.errors.message && (
                    <label className="error">{formik.errors.message}</label>
                  )}
                </div>
                <div className="text-right cta-btn from-btn">
                  <button type="submit" className="btn btn-style btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <ShortFooter />
    </React.Fragment>
  );
}

export default Contact;
