import React from "react";
import { NavLink } from "react-router-dom";
import ProductTile from "./ProductTile";

function HomeSessionThree() {
  return (
    <>
      <section className="home-section-product">
        <div className="container py-md-5 py-4">
          <div className="row align-items-center">
            <div className="col-lg-4 pe-lg-5">
              <div className="position-relative">
                <h4 className="title-head">
                  Transform your research with Nanatom's innovative solutions
                </h4>
              </div>
              <p className="">
                Nanatom offers customized off-the-shelf products that cater to
                your unique requirements, including advanced sensing technology,
                specialized machines, and material testing equipment. With our
                innovative solutions, you can lead the industry and stay ahead
                of the competition.
              </p>
              <div className="cta-btn">
                <NavLink className="btn btn-style" to="/offerings"> Discover More</NavLink>
              </div>
            </div>
            <div className="col-lg-8 col-sm-12">
              <div className="row">
                <ProductTile />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HomeSessionThree;
