import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import CustomerLeft from "./CustomerLeft";
import { toast } from 'react-toastify';
import api from "../api/services";
import OtpInput from 'react-otp-input';

const Register = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState('');
  const [regForm, setRegForm] = useState(true);
  const [OtpForm, setOtpForm] = useState(false);
  const [showResendBtn, setShowResendBtn] = useState(false);
  const [passwordForm, setPasswordForm] = useState(false);

  const validationSchema = Yup.object({
    full_name: Yup.string().required("Full Name is required"),
    email: Yup.string().email("Invalid email address").required("Email is required"),
  });

  const initialValues = {
    full_name: "",
    email: ""
  };

  const onSubmit = async (values, { resetForm }) => {
    const formData = new FormData();
    formData.append("full_name", values.full_name);
    formData.append("email", values.email);
    try {
      const registerData = await api("POST", "register", formData);
      if (registerData.status === 'success') {
        if (registerData.otp_status === 'VERIFIED') {
          setRegForm(false);
          setOtpForm(false);
          setPasswordForm(true);
        }else{
          localStorage.setItem('email', values.email)
          resetForm();
          window.scrollTo(0, 0);
          setRegForm(false);
          setOtpForm(true);
          setTimeout(() => {
            setShowResendBtn(true);
          }, 10000);
        }
      } else {
        console.log('show error');
      }
      toast(registerData.message);
    } catch (error) {
      console.log(error);
    }
  };

  const verifyOTP = async () => {
    if (otp.length === 6) {
      const otpFormData = new FormData();
      otpFormData.append("email", localStorage.getItem('email'));
      otpFormData.append("otp", otp);
      const verifyOTPData = await api("POST", "verifyOTP", otpFormData);
      if (verifyOTPData.status === 'success') {
        console.log("done")
        setOtpForm(false);
        setPasswordForm(true);
      } else {
        console.log('show error');
      }
      toast(verifyOTPData.message);
    } else {
      console.log("Please enter the otp")
    }
  };
  const resendOTP = async () => {
    const resendOtpFormData = new FormData();
    resendOtpFormData.append("email", localStorage.getItem('email'));
    const resendOTPData = await api("POST", "resendOTP", resendOtpFormData);
    if (resendOTPData.status === 'success') {
      setShowResendBtn(false);
      setTimeout(() => {
        setShowResendBtn(true);
      }, 10000);
      console.log("done")
    } else {
      console.log('show error');
    }
    toast(resendOTPData.message);
  };

  const initialResetValues = {
    newPassword: "",
    confirmPassword: ""
  };
  const validationSchemaPassword = Yup.object({
    newPassword: Yup.string()
      .required('No password provided.')
      .min(8, 'Password is too short - should be 8 chars minimum.')
      .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter.')
      .matches(/[0-9]/, 'Password must contain at least one digit.')
      .matches(/[^a-zA-Z0-9]/, 'Password must contain at least one special character.')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter.'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm Password is required")
  });

  const handilePasswordCreation = async (values, { resetForm }) => {
    const formDataPassword = new FormData();
    formDataPassword.append("password", values.password);
    formDataPassword.append("email", localStorage.getItem('email'));
    try {
      const registerData = await api("POST", "createPassword", formDataPassword);
      if (registerData.status === 'success') {
        resetForm();
        window.scrollTo(0, 0);
        navigate('/login', { replace: true });
      } else {
        console.log('show error');
      }
      toast(registerData.message);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <React.Fragment>
      <section className="py-5">
        <div className="container">
          <div className="row login py-5">
            <div className="col-lg-6 col-md-12 py-5 login-left">
              <CustomerLeft />
            </div>
            <div className="col-lg-6 col-md-12 py-5 login-form">

              {regForm &&
                <>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    <Form noValidate autoComplete="off">
                      <div className="mb-3">
                        <h2 className="mb-1">Register Here</h2>
                      </div>
                      <div className="form-item mb-2">
                        <label htmlFor="full_name" className="form-label">
                          Full Name
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          id="full_name"
                          name="full_name"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          name="full_name"
                          component="label"
                          className="error"
                        />
                      </div>
                      <div className="form-item mb-2">
                        <label htmlFor="email" className="form-label">
                          Email
                        </label>
                        <Field
                          type="email"
                          className="form-control"
                          id="email"
                          name="email"
                        />
                        <ErrorMessage
                          name="email"
                          component="label"
                          className="error"
                        />
                      </div>
                      <div className="button-section mt-3">
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                      <div className="register-link mt-4">
                        <p>
                          Click <NavLink to="/login">here</NavLink> to login.
                        </p>
                      </div>
                    </Form>
                  </Formik>
                </>
              }
              {OtpForm &&
                <div className="OTP">
                  <h2>OTP Verification</h2>
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderSeparator={<span>-</span>}
                    containerStyle={'optContainer'}
                    inputStyle={'otpInputBox'}
                    renderInput={(props) => <input {...props} />}
                  />
                  <div className="button-section mt-3">
                    <button type="submit" className="btn btn-primary" onClick={verifyOTP}>
                      Verify OTP
                    </button>
                    {showResendBtn && <button type="submit" className="btn btn-primary float-end" onClick={resendOTP}>
                      Resend OTP
                    </button>}
                  </div>
                </div>
              }
              {passwordForm &&
                <div className="createPassword">
                  <Formik
                    validationSchema={validationSchemaPassword}
                    initialValues={initialResetValues}
                    onSubmit={handilePasswordCreation}
                  >
                    <Form noValidate autoComplete="off">
                      <div className="mb-3">
                        <h2 className="mb-1">Create Password</h2>
                      </div>
                      <div className="form-item mb-2">
                        <label htmlFor="newPassword" className="form-label">
                          New Password
                        </label>
                        <Field
                          type="password"
                          className="form-control"
                          id="newPassword"
                          name="newPassword"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          name="newPassword"
                          component="label"
                          className="error"
                        />
                      </div>
                      <div className="form-item mb-2">
                        <label htmlFor="confirmPassword" className="form-label">
                          Confirm Password
                        </label>
                        <Field
                          type="password"
                          className="form-control"
                          id="confirmPassword"
                          name="confirmPassword"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          name="confirmPassword"
                          component="label"
                          className="error"
                        />
                      </div>
                      <div className="mt-3 button-section cta-btn">
                        <button type="submit" className="btn btn-primary btn-style">
                          Submit
                        </button>
                      </div>
                    </Form>
                  </Formik>
                </div>
              }
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Register;
