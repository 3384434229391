import React from "react";

function NoPage() {
  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 no-page">
            <h1>404</h1>
            <p className="">Page Not Found</p>
          </div>
          <div className="col-12 no-page-bottom">
            <h3>Sorry, we can't find the page you're looking for.</h3>
            <p className="">
              You may have mistyped the web address, or a link could be broken.
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default NoPage;
