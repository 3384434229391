import React, { useState, useEffect } from "react";

function ViewProfile(profile) {
    const [p, setprofile] = useState([]);

    useEffect(() => {
        setprofile(profile.props);
    }, [profile]);
    return (
        <>
            <div className="profile">
                <div className="profile-data">
                    <div className="profile-data-item">
                        <label>Full Name : </label><span>{p.full_name}</span>
                    </div>
                    <div className="profile-data-item">
                        <label>Address : </label><span>{p.address}</span>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ViewProfile;
