import React, { useEffect, useState, useRef } from "react";
import { useParams } from 'react-router-dom';
import CustomerWelcome from "../common/CustomerWelcome";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import api from "../../api/services";
import util from "../../Utils";
import { formatDate } from "../../dateUtils";
import { toast } from 'react-toastify';

function TicketDetails() {
  const { ticketId } = useParams();
  const [ticketData, setTicketData] = useState();
  const [ticketCommentsData, setTicketCommentsData] = useState();
  const quillRef = useRef();
  const [fl, sfl] = useState(true);

  useEffect(() => {
    fetchTicketDetails();
  }, []);


  const fetchTicketDetails = async () => {
    try {
      const tData = await api("GET", "ticketDetails?id=" + ticketId, true);
      if (tData) {
        setTicketData(tData);
        getTicketConversations();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTicketConversations = async () => {
    try {
      const tCData = await api("GET", "ticketComments?id=" + ticketId, true);
      if (tCData) {
        setTicketCommentsData(tCData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const initialValues = {
    comment: ""
  };

  const validationSchema = Yup.object({
    comment: Yup.string().required("Please add a comment"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    const formData = new FormData();
    formData.append("ticket_id", ticketId);
    formData.append("comment_text", values.comment);
    formData.append("user_id", util.customer_id);
    formData.append("user_type", "customer");
    try {
      const pData = await api("POST", "commentTicket", formData, true);
      if (pData.status === 'success') {
        resetForm();
        window.scrollTo(0, 0);
        const newticketCommentData = { comment_id: '', ticketId: ticketId, user_id: util.customer_id, user_type: 'customer', comment_text: values.comment, comment_date: new Date() }
        ticketCommentsData.push(newticketCommentData);
      } else {
        console.log('show error');
      }
      toast(pData.message);
      quillRef.current.getEditor().setText('');
      sfl(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="customer-content">
        <CustomerWelcome />
        <div className="customer-content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-head">
                    <label className="">Ticket Number : {ticketData?.ticket_number}</label>
                    <span className="">Ticket Status : {ticketData?.status}</span>
                    <div className="">
                      <label className="">Product Name : {ticketData?.product_name}</label>
                      <span className="">Created Date : {formatDate(ticketData?.created_at)}</span>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="ticket-details">
                      <label className="ticket-details-heading">Title : {ticketData?.title}</label>
                      <div>
                        <label>Description : </label>
                      </div>
                      <div dangerouslySetInnerHTML={{ __html: ticketData?.description }} ></div>
                    </div>
                    {ticketCommentsData &&
                      <>
                        {ticketCommentsData.map((item) => (
                          <div className="ticket-conversations">
                            <div className="ticket-conversations-details">
                              <div className="ticket-conversations-details-name">
                                {item.user_type === 'admin' ? "Nanatom" : util?.full_name}
                              </div>
                              <div className="ticket-conversations-details-date">{formatDate(item?.comment_date)}</div>
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: item?.comment_text }} ></div>
                          </div>
                        ))}
                      </>
                    }
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                    >
                      {({ setFieldValue }) => (
                        <Form>
                          <div className="form-item mt-3">
                            <label htmlFor="comment">Reply</label>
                            <ReactQuill
                              id="comment"
                              name="comment"
                              // value={values.description}
                              ref={quillRef}
                              onChange={(content) => {
                                setFieldValue("comment", content)
                                sfl(true)
                              }}
                              modules={{
                                toolbar: [
                                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                  ["bold", "italic", "underline", "strike", "blockquote"],
                                  [{ list: "ordered" }, { list: "bullet" }],
                                  ["link", "image"],
                                  ["clean"]
                                ]
                              }}
                              formats={[
                                "header",
                                "bold",
                                "italic",
                                "underline",
                                "strike",
                                "blockquote",
                                "list",
                                "bullet",
                                "link",
                                "image"
                              ]}
                            />
                            <ErrorMessage name="comment" component="div" className="error" />
                          </div>

                          <div className="mt-3 button-section cta-btn">
                            <button type="submit" className="btn btn-primary btn-style btn-sm" disabled={!fl}>Add Comment</button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

export default TicketDetails;
