import React from "react";

function HomeSessionFour() {
  return (
    <>
      <section className=" py-5 home-section-two">
        <div className="container py-md-5 py-4">
          <div className="row align-items-center">
            <div className="col-lg-12 pe-lg-5">
              <div className="position-relative">
                <h2 className="title-style">
                  Specialized Custom build Machines
                </h2>
              </div>
              <p className="">
                We build machines which stands for very specific use such as
                High temperature Hardness Test or Tensile Test
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HomeSessionFour;
