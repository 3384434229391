import React from "react";
import { NavLink } from "react-router-dom";

function ClientLogos() {
  const clientList = ["logo1.png", "logo2.png", "logo3.png", "logo4.png", "logo5.png","logo6.png"];
  return (
    <>
      <section className="home-section-client">
        <div className="container">
          <div className="row align-items-center">

            <div className="col-lg-12 footer-logos">
              <img src="./image/makeinindia.png" />
              <img src="./image/startup.png" />
            </div>

            <h2 className="title-style text-center">Our Clients</h2>
            <div className="col-lg-12 client-wrapper">
              {clientList.map((client, index) => (
                <div className="client">
                  <div className="client-item">
                    <img src={"./image/clientLogos/" + client} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ClientLogos;
